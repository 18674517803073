import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FingoNavbar } from '@fingo/lib/components/layout';
import {
  useBooleanState,
  useDynamicFingoLogo,
  useGetUser,
  useIsLogged,
  useSelectedCompany,
} from '@fingo/lib/hooks';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { makeStyles, styled } from '@mui/styles';
import LoggedOutMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedOutMobileNavItems';
import LoggedInMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedInMobileNavItems';
import SelectCompanyNavItem from '@fingo/lib/components/company/SelectCompanyNavItem';
import ShoppingCart from '../../shopping/shoppingCart';
import { useFingoRoutes, useShowingTyC } from '../../../hooks';

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 90,
    objectFit: 'contain',
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const NO_SHOPPING_CART_ROUTES = ['/app/home', '/app/invoice'];

const MobileNavbar = () => {
  const routes = useFingoRoutes().filter(({ showInNavbar }) => showInNavbar);
  const isLogged = useIsLogged();
  const [drawerOpen, toggleDrawer] = useBooleanState(false);
  const classes = useStyles();
  const selectedCompany = useSelectedCompany();
  const user = useGetUser();
  const showTyc = useShowingTyC();
  const fingoLogo = useDynamicFingoLogo();
  const { pathname } = useLocation();
  const showShoppingCart = isLogged && !showTyc && !NO_SHOPPING_CART_ROUTES.includes(pathname);

  return (
    <>
      <FingoNavbar>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={Link} to="/" width="25%">
            <img src={fingoLogo} alt="logo" className={classes.logo} />
          </Box>
          <Stack
            width="75%"
            direction="row"
            alignItems="center"
            flexGrow="1"
            justifyContent="flex-end"
          >
            {selectedCompany && <SelectCompanyNavItem ml={0} />}
            {!showTyc && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                id="mobile-nav-toggle"
              >
                <Menu color="primary" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </FingoNavbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 280,
            bgcolor: 'text.main',
            pb: 4,
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer} id="mobile-nav-close">
            <Close color="fingoWhite" />
          </IconButton>
        </DrawerHeader>
        {isLogged && user && (
          <LoggedInMobileNavItems closeDrawer={toggleDrawer} routes={routes} />
        )}
        {!isLogged && <LoggedOutMobileNavItems />}
      </Drawer>
      {showShoppingCart && (
        <AppBar
          elevation={0}
          position="fixed"
          color="transparent"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Toolbar>
            <Stack
              width="100%"
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <ShoppingCart />
            </Stack>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default MobileNavbar;
