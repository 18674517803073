import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import BulletListItem from '../list-items/BulletListItem';

const BulletList = ({ title, titleProps, items, ...props }) => (
  <>
    {title && <Typography {...titleProps}>{title}</Typography>}
    <List {...props}>
      {items.map(({ id, primary, color }) => (
        <BulletListItem
          key={id}
          alignItems="flex-start"
          disableGutters
          disablePadding
          color={color}
        >
          <ListItemText sx={{ m: 0 }}>{primary}</ListItemText>
        </BulletListItem>
      ))}
    </List>
  </>
);

BulletList.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.objectOf(
    PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      primary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      color: PropTypes.string,
    }),
  ).isRequired,
};

BulletList.defaultProps = {
  title: null,
  titleProps: {},
};

export default BulletList;
